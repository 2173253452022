<template>
  <div class="delete">
    <div class="icon">
      <i class="el-icon-warning"></i>
      <span class="title">{{
        `确认要删除${this.typeName} “ ${name} ” 吗？ 删除后不可再恢复!`
      }}</span>
    </div>
    <div class="btn-box">
      <el-button class="btn" type="primary" @click="getDelFunc">确定</el-button>
      <el-button @click="handleClose">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeleteAny",
  props: {
    name: String,
    handleClose: Function,
    getDelFunc: Function,
    typeName: String,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.icon {
  width: 100%;
  margin-bottom: 20px;
  .el-icon-warning {
    vertical-align: middle;
    color: red;
    font-size: 30px;
    margin-right: 15px;
  }
  .title {
    vertical-align: middle;
    color: #000000;
    font-size: 14px;
  }
}
.btn-box {
  text-align: right;
  .btn {
    margin-right: 20px;
  }
}
</style>
